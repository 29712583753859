import * as React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import ImageHeader from '../components/ImageHeader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBookmark} from '@fortawesome/pro-solid-svg-icons'
import {graphql} from 'gatsby'
import IndexFilter from '../components/IndexFilter'
import Layout from "../components/Layout"
import Seo from '../components/Seo'
import '../styles/index.scss'

const ScripturesPage = ({data}) => {

    const oldTestamentBooks = ['genesis', 'exodus', 'leviticus', 'numbers', 'deuteronomy', 'joshua', 'judges', 'ruth', '1-samuel', '2-samuel', '1-kings', '2-kings', '1-chronicles', '2-chronicles', 'ezra', 'nehemiah', 'esther', 'job', 'psalms', 'proverbs', 'ecclesiastes', 'song-of-solomon', 'isaiah', 'jeremiah', 'lamentations', 'ezekiel', 'daniel', 'hosea', 'joel', 'amos', 'obadiah', 'jonah', 'micah', 'nahum', 'habakkuk', 'zephaniah', 'haggai', 'zechariah', 'malachi']

    let oldTestamentObjects = []

    oldTestamentBooks.forEach(function (name, index) {
        const thisBook = data.allWpScripture.nodes.find(book => book.slug === name)
        oldTestamentObjects.push(thisBook)
    })

    const newTestamentBooks = ['matthew', 'mark', 'luke', 'john', 'acts', 'romans', '1-corinthians', '2-corinthians', 'galatians', 'ephesians', 'philippians', 'colossians', '1-thessalonians', '2-thessalonians', '1-timothy', '2-timothy', 'titus', 'philemon', 'hebrews', 'james', '1-peter', '2-peter', '1-john', '2-john', '3-john', 'jude', 'revelation']

    let newTestamentObjects = []

    newTestamentBooks.forEach(function (name, index) {
        const thisBook = data.allWpScripture.nodes.find(book => book.slug === name)
        newTestamentObjects.push(thisBook)
    })

    return (
        <Layout>
            <Seo
                title={`${data.wpResourceType.name} by Scripture`}
                description={`Index for ${data.wpResourceType.name} by Scripture`}
            />
            <ImageHeader title={`${data.wpResourceType.name} by Scripture`}
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Scriptures" isResource={true}/>
            <IndexFilter taxonomy="scriptures"/>
            <section className="index index--scripture container">
                <div className="index__group">
                    <div className="index__group__title">
                        <div className="index__group__title__first">
                            <h6>Old Testament</h6>
                        </div>
                    </div>
                    <ul className="index__group__children">
                        {oldTestamentObjects.map((book, index) =>
                            book?.count > 0 ?
                                <li className="index__group__children__child" key={index}>
                                    <a href={`/resources/scripture/${book.slug}/${data.wpResourceType.slug}`}>
                                        <i><FontAwesomeIcon icon={faBookmark}/></i>
                                        {book.name}
                                    </a>
                                </li>
                                :
                                ''
                        )}
                    </ul>
                </div>
                <div className="index__group">
                    <div className="index__group__title">
                        <div className="index__group__title__first">
                            <h6>New Testament</h6>
                        </div>
                    </div>
                    <ul className="index__group__children">
                        {newTestamentObjects.map((book, index) =>
                            book?.count > 0 ?
                                <li className="index__group__children__child" key={index}>
                                    <a href={`/resources/scripture/${book.slug}/${data.wpResourceType.slug}`}>
                                        <i><FontAwesomeIcon icon={faBookmark}/></i>
                                        {book?.name}
                                    </a>
                                </li> : ''
                        )}
                    </ul>
                </div>
            </section>
        </Layout>
    )
}

export default ScripturesPage

export const query = graphql`
    query ($type: String!) {
        allWpScripture(
            filter: {mereResources: {nodes: {elemMatch: {resourceTypes: {nodes: {elemMatch: {slug: {eq: $type}}}}}}}}
        ) {
            nodes {
                name
                id
                slug
                parentDatabaseId
                count
            }
        }
        wpResourceType(slug: {eq: $type}) {
            name
            slug
        }
        wp {
            resourcesGeneralSettings {
                resourceLibraryHeader {
                    headerImage {
                        sourceUrl
                    }
                }
            }
        }
    }
`